.public-sidebar-item {
    display: flex;
    flex-grow: 1;
    padding: 12px 0px;
    border-bottom: thin solid #C7C7CC;
}

.public-sidebar-dropdown > .public-sidebar-title {
    color: black;
    background-color: #fff;
    border: 0px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}

.public-main-title{
    text-transform: none;
}

.public-sidebar-title {
    text-transform: capitalize;
    margin-Left: 10px;
}

.public-sidebar-title:focus {
    box-shadow: none;
}


.public-sidebar-icon {
    height: 22px;
    width: 22px;
    background-color: #fff;
}

.public-sidebar-container {
    border-radius: 9px;
    background-color: #fff;
}

.dropdown-menu-scroll{
    max-height: 300px;
    overflow: auto;
}