.splash-page {
    display: flex;
    height: 100vh;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    margin: 0;
}


.splash-page p {
    font-size: 32px;
    letter-spacing: 1px;
}

.splash-page img {
    height: 120px;
    background-size: cover;
}