.availability-container {
    background-color: #fff;
    border-radius: 9px;
    overflow: hidden;
}

@media only screen and (min-width: 992px) {
    .availability-container .selected {
        background-color: #E5E4E9;
    }
}
.availability-container .availability-item:active {
    background-color: #E5E4E9;
}
.availability-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
}

.setting-icon {
    background-color: #E5E5E5;
    height: 22px;
    width: 22px;
}

.watch-icon {
    background-color: #FAE9E9;
    height: 22px;
    width: 22px;
}

.availability-title-container {
    border-bottom: thin solid #C7C7CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
    padding: 12px 0px;
}

.btn-delete-availability {
    padding: 6px 45px;
    background-color: #E5E5EA;
    font-size: 18px;
    color: #dc3545;
    border-radius: 10px;
    border: 0px;
    margin-bottom: 10px;
    white-space: nowrap;
    min-width: 230px;
}
