.signin-container>.row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.signin-container>.row>.signin-card {
    box-shadow: 4px 12px 20px #eee, -4px -6px 20px #eee;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
}

.signin-card>.we-cal-title {
    font-family: robotoBold;
    font-size: 26px;
    margin-bottom: 25px;
}

.signin-card>.we-cal-desc {
    font-family: robotoNormal;
    font-size: 16px;
    margin-bottom: 25px;
}

.signin-card>.update-modal-link-error {
    font-family: robotoBold;
    font-size: 14px;
    margin-bottom: 25px;
}

.signin-card>.signup-label {
    font-family: robotoBold;
    color: #787d86;
    font-size: 16px;
    align-self: flex-start;
}

.editable-button-container>.btn-save:disabled {
    color: #a4a4ad;
}

.signin-card>div>.input-url-link {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid darkgrey;
}

.signin-card>div>.url {
    font-family: robotoBold;
    font-size: 20px;
}

.signin-card>div .signup-terms {
    font-family: robotoNormal;
    font-size: 14px;
    margin-left: 12px;
}

.signin-brand-logo {
    height: 50px;
}

.signin-card>.signin-button {
    background-color: #fff;
    border-radius: 6px;
    justify-content: center;
    padding: 8px 12px;
    position: relative;
    align-items: center;
    cursor: pointer;
    display: flex;
    border: 2px solid #acb1b9;
    width: 100%;
}

.signin-button .signin-label {
    font-size: 16px;
    cursor: pointer;
    color: #787d86;
}

.signin-button .google-logo {
    height: 22px;
    width: 22px;
}