.btn-copy-calendar-link {
    background: #F2F2F2;
    padding-right: 15px;
    padding-top: 1px;
    padding-left: 15px;
    border-radius: 6px;
    font-size: 13px;
    cursor: pointer;
    font-family: robotoBold;
}

.btn-open-link {
    background: #F2F2F2;
    padding-right: 15px;
    padding-top: 1px;
    padding-left: 15px;
    border-radius: 6px;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    color: #26292d;
    font-weight: bold;
}

.editable-button-container>.btn-save {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    width: 160px;
    color: #0a0afe;
    padding: 10px;
    border: 0px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.editable-button-container>.btn-cancel {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    padding: 10px;
    width: 160px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.duration-dropdown>.dropdown-menu>.dropdown-item {
    color: #3D3D3D;
}

.duration-dropdown>div {
    cursor: pointer;
    font-size: 15px;
    height: 34px;
    align-items: center;
}

.duration-dropdown>div>span {
    line-height: 22px;
}

.duration-dropdown {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 1px #ccc, -1px -1px 1px #ccc;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

}

.duration-dropdown>.dropdown-menu {
    width: 100%;
    padding: 0px;
    border-radius: 0px;
    height: 26px;
}

.duration-dropdown>.dropdown-menu>.dropdown-item:hover {
    background-color: #e5e5ea;
}

.duration-dropdown>.dropdown-menu>.dropdown-item:active {
    background-color: #e5e5ea;
}

.duration-dropdown>.dropdown-menu>[class *="dropdown-"] {
    padding: 0rem;
    font-family: robotoNormal;
    padding-left: 4px;
}

.meeting-label {
    color: darkgray;
    font-family: robotoNormal;
    font-size: 12px;
}

.description-textarea {
    border: 0px;
    border-radius: 9px;
    min-height: 110px !important;
    box-shadow: 0px 1px 1px #ccc;
}

.info-text {
    font-size: 15px;
    font-family: robotoNormal;
    /* margin-top: 50px; */
}

.txt-duration {
    border: 0px;
    border-radius: 8px;
}

.description-textarea:disabled {
    background-color: #fff;
}

.txt-duration:disabled {
    background-color: #fff;
}

.txt-calendar-name:disabled {
    background-color: #fff;
}

.txt-calendar-link:disabled {
    background-color: #fff;
}


.duration-container {
    background-color: #fff;
    box-shadow: 0px 1px 1px #ccc;
    border-radius: 8px;
}

.duration-container label {
    width: 200px;
}

.btn-edit-meeting-details {
    color: #007AFF;
    background-color: transparent;
    border: 0px;
}

.btn-edit-meeting-details:disabled {
    cursor: no-drop;
}

.txt-calendar-name {
    border: 0px;
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px;
    border-radius: 9px;
}

.txt-calendar-link {
    border: 0px;
    border-radius: 0px;
    padding: 0px;
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
}

.calendar-link-component {
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px;
    background-color: #fff;
    display: flex;
    justify-content: between;
    align-items: center;
    border-radius: 9px;
}

.form-control[readonly] {
    background-color: #fff;
}

.editable-button-container {
    display: flex;
}

.formik-error {
    font-size: 14px;
    color: #C70000;
}

.dropdown-icon-container{
    background-color: #52A5FC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 100%;
    width: 30px;
}


.dropdown-icon-container > .dropdown-icon {
    height: 8px;
    width: 11px;
}

@media only screen and (max-width: 576px) {
    .editable-button-container {
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }

    .editable-button-container>.btn-save {
        width: 250px;
    }

    .editable-button-container>.btn-cancel {
        width: 250px;
    }
}