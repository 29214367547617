.calendar-item-title {
    text-transform: capitalize;
}

@media only screen and (min-width: 992px) {
    .calendar-container .selected {
        background-color: #E5E4E9;
    }

    .element-container .selected {
        background-color: #E5E4E9;
    }
}

.calendar-container .calendar-item:active {
    background-color: #E5E4E9;
}

.element-container .element-item:active {
    background-color: #E5E4E9;
}


.element-item-title {
    text-transform: capitalize;
}

.profile-direction {
    display: flex;
    align-items: center;
}

.profile-card {
    height: 62px;
    background-color: #fff;
    border-radius: 6px;
    justify-content: space-between;
    padding: 12px;
    position: relative;
    align-items: center;
    cursor: pointer;
    display: flex;
}

.profile-name {
    font-size: 25px;
    cursor: pointer;
}


.add-icon {
    height: 22px;
    width: 22px;
    background-color: #efeff4;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
}

.add-icon img {
    height: 12px;
    width: 12px;
}

.search-box {
    align-items: center;
    background-color: #D8D8DD;
    border-radius: 9px;
    display: flex;
    overflow: hidden;
    min-height: 36px;
}

.search-box input {
    background-color: #D8D8DD;
}

.search-box input:focus {
    background-color: #D8D8DD;
    box-shadow: none;
}

.element-heading {
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.top-radius {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.bottom-radius {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.calendar-container {
    border-radius: 9px;
    background-color: #fff;
}

.profile-image {
    height: 40px;
    width: 40px;
}

.profile-image-container {
    border-radius: 50% !important;
    overflow: hidden;
    position: absolute;
}

.calendar-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
}

.video-meeting-icon {
    background-color: #F1EBE5;
    height: 22px;
    width: 22px;
}

.video-meeting-icon img {
    height: 14px;
    width: 14px;
}

.element-container {
    border-radius: 9px;
    background-color: #fff;
}

.element-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 16px;
}

.video-meeting-title-container {
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    border-bottom: thin solid #C7C7CC;
    padding: 12px 0px;
}

.calendar-title-container {
    border-bottom: thin solid #C7C7CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
    padding: 12px 0px;
}

.calendar-icon {
    height: 22px;
    width: 22px;
}

.calendar-icon img {
    height: 14px;
    width: 14px;
}

.profile-container {
    padding: 18px;
}

.lbl-calendar-component {
    color: #8E8E93;
    font-size: 13px;
}

.lbl-element-component {
    color: #8E8E93;
    font-size: 13px;
}

.support-button .help-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: #01296A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-button .help-icon img {
    height: 24px;
    width: 24px;
}

.support-button .help-label {
    color: black;
    font-size: 16px;
    cursor: pointer;
}

.support-button {
    padding: 12px 21px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.home-sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
